@import url("https://fonts.googleapis.com/css2?family=Jost:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;


html {
    scroll-behavior: smooth;
}
body {
    font-family: "Jost", sans-serif;
    background-color: #0f172a ;
    right: 0;
}

@layer base {
    h3 {
        @apply font-semibold uppercase lg:text-[50px] md:text-[40px] text-4xl;
    }
}

@layer components {
    .btn2 {
        @apply inline-block px-2 py-1.5 items-center justify-center text-xs font-medium uppercase tracking-[2px] transition-all duration-300 ease-in;
    }
    .btn {
        @apply inline-block px-3 py-[5px] text-sm font-medium uppercase tracking-[2px] transition-all duration-300 ease-in;
    }

    .btn-theme {
        @apply bg-purple-900 hover:bg-slate-900 text-white shadow-white hover:shadow-purple-900;
    }
    .btn-theme2 {
        @apply bg-purple-900 hover:bg-slate-900 text-white shadow-black hover:shadow-purple-900;
    }
    .btn-whapp {
        @apply rounded-full bg-green-400 hover:bg-purple-900 text-purple-900 shadow-purple-500 hover:shadow-green-300 hover:text-white;
    }
    .btn-dark {
        @apply bg-slate-900 hover:bg-purple-900 text-white shadow-black shadow-purple-900 hover:shadow-slate-900;
    }
    .btn-yellow {
        @apply bg-yellow-500 hover:bg-purple-900 text-purple-900 shadow-yellow hover:shadow-yellow-500 hover:text-white;
    }
    .btn-white {
        @apply bg-slate-200 p-1 hover:bg-purple-900 text-purple-900 shadow-yellow hover:shadow-yellow-500 hover:text-white;
    }
    .btn-red {
        @apply bg-red-500 p-1 hover:bg-slate-300 text-black shadow-white shadow-md;
    }
    .btn-theme2r {
        @apply bg-red-800 hover:bg-slate-900 text-white shadow-black hover:shadow-red-800;
    }
}


.active {
    color: #910cea;
}

.slick-slide div {
    margin: 0 10px 10px 10px;
}


/* Estilo para el tooltip */
*[title] {
    position: relative;
    display: inline-block;
}

*[title]::after {
    content: attr(title);
    visibility: hidden;
    width: max-content;
    background-color: #a855f7;
    color: white;
    text-align: center;
    font-size: small;
    padding: 3px;
    border-radius: 6px;
    position: absolute;
    z-index: 100;
    top: -30px;
    left: 0%;
    transform: translateX(-10%);
    cursor: help;
    white-space: nowrap;
    pointer-events: none;
}

*[title]:hover::after {
    visibility: visible;
}


.shadowFuchsia {
    box-shadow: 0 0 10px 0 #ff00ff;
}

